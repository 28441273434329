import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import "../assets/scss/App.scss";

import MainContextProvider from "../contexts/MainContext";
import ComponentContextProvider from "../contexts/ComponentContext";

import UnAuthorized from "../roles/UnAuthorized";
import Admin from "../roles/Admin";

import PageLayout from "../layouts/PageLayout";
import ProjectLayout from "../layouts/ProjectLayout";
import ZoneLayout from "../layouts/ZoneLayout";

import LoginPage from "./Login";
import LogoutPage from "./Logout";
import ProjectPage from "./Project";
import FieldPage from "./Field";
import ZonePage from "./Zone";
import ApprovalPage from "./Approval";
import AICameraPage from "./AICamera";
import MachinePage from "./Machine";
import WorkerPage from "./Worker";
import ZonerPage from "./Zoner";
import PhonePage from "./Phone";
import UserListPage from "./User/List";
import UserCreatePage from "./User/Create";

import Loading from "../components/Loading";
import Alert from "../components/Alert";
import GasSensorPage from "./GasSensor";
import ELockPage from "./ELock";
import EnvSensorPage from "./EnvSensor";
import ProjectConfigPage from "./ProjectConfig";
import GisMapListPage from "./GisMapList";
import SmartWatchPage from "./SmartWatch";
import SensorConfigPage from "./SensorConfig";
import SensorConfigLayout from "../layouts/SensorConfigLayout";

const App = () => {
    return (
        <ComponentContextProvider>
            <Router basename={`/${process.env.REACT_APP_PREFIX}`}>
                <MainContextProvider>
                    <Loading componentLoader={false} />
                    <Alert />
                    <Routes>
                        <Route
                            path="/login"
                            element={
                                <UnAuthorized>
                                    <LoginPage />
                                </UnAuthorized>
                            }
                        />

                        <Route
                            path="/users"
                            element={
                                <Admin>
                                    <PageLayout
                                        component={<UserListPage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/users/create"
                            element={
                                <Admin>
                                    <PageLayout
                                        component={<UserCreatePage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/"
                            element={
                                <Admin>
                                    <PageLayout
                                        component={<ProjectPage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/fields"
                            element={
                                <Admin>
                                    <PageLayout
                                        component={<FieldPage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:id/zones/"
                            element={
                                <Admin>
                                    <ProjectLayout
                                        component={<ZonePage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:projectID/:zoneID/workers"
                            element={
                                <Admin>
                                    <ZoneLayout
                                        component={<WorkerPage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:projectID/:zoneID/zoners"
                            element={
                                <Admin>
                                    <ZoneLayout
                                        component={<ZonerPage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:projectID/:zoneID/phones"
                            element={
                                <Admin>
                                    <ZoneLayout
                                        component={<PhonePage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:id/approvals"
                            element={
                                <Admin>
                                    <ProjectLayout
                                        component={<ApprovalPage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:id/ai-cameras"
                            element={
                                <Admin>
                                    <ProjectLayout
                                        component={<AICameraPage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:id/machines"
                            element={
                                <Admin>
                                    <ProjectLayout
                                        component={<MachinePage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:id/gas-sensors"
                            element={
                                <Admin>
                                    <ProjectLayout
                                        component={<GasSensorPage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:id/env-sensors"
                            element={
                                <Admin>
                                    <ProjectLayout
                                        component={<EnvSensorPage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:id/e-locks"
                            element={
                                <Admin>
                                    <ProjectLayout
                                        component={<ELockPage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:id/smart-watches"
                            element={
                                <Admin>
                                    <ProjectLayout
                                        component={<SmartWatchPage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:id/project-config"
                            element={
                                <Admin>
                                    <ProjectLayout
                                        component={<ProjectConfigPage sidebar={true} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:id/gis-map-list"
                            element={
                                <Admin>
                                    <ProjectLayout
                                        component={<GisMapListPage sidebar={true} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />

                        <Route
                            path="/:zoneId/:sensorId/:sensorType/sensorConfig"
                            element={
                                <Admin>
                                    <SensorConfigLayout
                                        component={<SensorConfigPage sidebar={`with-sidebar`} />}
                                        sidebar={`with-sidebar`}
                                    />
                                </Admin>
                            }
                        />
                    </Routes>
                </MainContextProvider>
            </Router>
        </ComponentContextProvider>
    );
};

export default App;
