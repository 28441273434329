import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { Table, SelectPicker, Pagination, Toggle } from "rsuite";
import { isMobile } from "react-device-detect";

import { MainContext } from "../contexts/MainContext";
import Loading from "./Loading";
import API from "../helpers/API";
import { ComponentContext } from "../contexts/ComponentContext";

const { HeaderCell, Cell, Column, ColumnGroup } = Table;

type Schema = {
    key: string;
    name: string;
    width: number;
};

type Pagination = {
    pages: number;
    current: number;
    total: number;
    size: number;
};

type Filter = {
    page: number;
    size: number;
    total: number;
    keyword: string;
};

type TableProps = {
    height?: number;
    rowHeight?: number;
    fetched: boolean;
    dataSource: string;
    searchable: boolean;
    pagination: boolean;
    sortable: boolean;
    viewable: boolean;
    insertable: boolean;
    editable: boolean;
    removable: boolean;
    schema?: Schema[] | [];
    data?: any;
    dataUrl?: string;
    machine?: any;
    controlZone?: any;
    style?: string;
    onDataFetch?: () => void;
    onFetched?: () => void;
    onViewButtonClick?: (rowData: any) => void;
    onInsertButtonClick?: (rowData: any) => void;
    onEditButtonClick?: (rowData: any) => void;
    onRemoveButtonClick?: (rowData: any) => void;
    toggleMachine?: (rowData: any, checked: boolean) => void;
    toggleControlZone?: (rowData: any, checked: boolean) => void;
};

type Sorting = {
    column: any;
    type: any;
};

type TableState = {
    schema: Schema[] | [] | undefined;
    data: [] | undefined;
};

const DataTable = (props: TableProps) => {
    const { alert, setAlert } = useContext(ComponentContext);
    const { main, resetToken } = useContext(MainContext);

    const [pagination, setPagination] = useState<Pagination>({
        pages: 0,
        current: 1,
        total: 0,
        size: 20
    });

    const [sorting, setSorting] = useState<Sorting>({
        column: undefined,
        type: undefined
    });

    const [keyword, setKeyword] = useState<string | null>(null);

    const [table, setTable] = useState<TableState>({
        schema: [],
        data: []
    });

    const stripHtml = (html: string) => {
        // Create a new div element
        var temporalDivElement = document.createElement("div");
        // Set the HTML content with the providen
        temporalDivElement.innerHTML = html;
        // Retrieve the text property of the element (cross-browser support)
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    };

    const matchAny = (data: any, filterParams: any) => {
        var checkVal = filterParams.value.toLowerCase();
        var tmpIsCols = Array.isArray(filterParams.columns);
        var tmpKeyList = data;

        if (tmpIsCols) {
            tmpKeyList = {};
            for (var iPos in filterParams.columns) {
                var tmpCol = filterParams.columns[iPos];
                var tmpKey = tmpCol.field;
                if (tmpKey) {
                    tmpKeyList[tmpKey] = true;
                }
            }
        }

        var match = false;
        var key = "";

        if (filterParams.startsWith === true) {
            for (key in tmpKeyList) {
                console.log(stripHtml(data[key]));
                if (stripHtml(data[key]).toLowerCase().indexOf(checkVal) === 0) {
                    match = true;
                    break;
                }
            }
        } else {
            outer: for (key in tmpKeyList) {
                if (data[key] !== null) {
                    if (typeof data[key] === "object") {
                        for (const item in data[key]) {
                            if (stripHtml(data[key][item]).toLowerCase().indexOf(checkVal) > -1) {
                                match = true;
                                break outer;
                            }
                        }
                    } else {
                        if (stripHtml(data[key]).toLowerCase().indexOf(checkVal) > -1) {
                            match = true;
                            break;
                        }
                    }
                }
            }
        }

        return match;
    };

    const search = (keyword: string) => {
        setTimeout(function () { }, 300);
    };

    const updatePagination = useCallback(
        (properties: any) => {
            setPagination({
                ...pagination,
                ...properties
            });
        },
        [pagination]
    );

    const fetchData = useCallback(
        async (search: boolean = false) => {
            if (typeof props.onDataFetch === "function") props.onDataFetch();

            const url = new URL(props.dataUrl as string);
            const params = new URLSearchParams(url.search);

            params.append("page", pagination.current.toString());
            params.append("size", pagination.size.toString());
            params.append("keyword", keyword !== null ? keyword : "");
            params.append("sortColumn", sorting.column);
            params.append("sortType", sorting.type);

            const queryUrl = `${url.protocol}//${url.host}${url.pathname}`;

            API.call({
                url: `${queryUrl}?${params.toString()}`,
                options: {
                    method: "get",
                    credentials: "include",
                    headers: { "Content-Type": "application/json" }
                },
                successCallback: (res) => {
                    if (typeof props.onFetched === "function") props.onFetched();

                    setTable({
                        ...table,
                        schema: res.data.schema,
                        data: res.data.records
                    });

                    setPagination({
                        ...pagination,
                        pages: Math.ceil(res.data.total / pagination.size),
                        total: res.data.total
                    });
                },
                errorCallback: (error) => {
                    setAlert({
                        ...alert,
                        type: error.message.type,
                        message: error.message.content
                    });
                },
                resetToken: resetToken
            });
        },
        [props.dataSource, pagination, keyword, sorting]
    );

    const actionButtonWidth = () => {
        let width = 0;

        if (props.viewable) width += 120;

        if (props.insertable) width += 120;

        if (props.editable) width += 120;

        if (props.removable) width += 120;

        if (typeof props.machine !== "undefined") width += 120;

        return width;
    };

    useEffect(() => {
        const onDelayedInput = setTimeout(() => {
            if (props.dataSource === "REMOTE" && keyword !== null) fetchData();
        }, 300);

        return () => clearTimeout(onDelayedInput);
    }, [keyword]);

    useEffect(() => {
        if (props.dataSource === "REMOTE") fetchData();
    }, [props.dataSource, pagination.current, pagination.size, sorting]);

    useEffect(() => {
        if (!props.fetched && props.dataSource === "REMOTE") fetchData();
    }, [props.fetched]);

    useEffect(() => {
        if (props.dataSource === "LOCAL") {
            console.log(props.schema);
            console.log(props.data);

            setTable({
                ...table,
                schema: props.schema,
                data: props.data
            });

            if (typeof props.onFetched === "function") props.onFetched();
        }
    }, [props.dataSource, props.fetched, props.data]);

    return (
        <>
            <div className="row">
                <div className="tableContainer col">
                    <div className="d-xl-flex d-block justify-content-between align-items-center">
                        {(props.searchable || props.pagination) && (
                            <div className="d-flex mb-5 filter align-items-center w-100">
                                {props.searchable && (
                                    <div className="pageSearch w-100">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            onKeyUp={(e) => {
                                                const target = e.target as HTMLInputElement;
                                                const value = target.value;

                                                setKeyword(value);
                                            }}
                                        />
                                    </div>
                                )}
                                {props.pagination && (
                                    <div className="pageSize ml-3">
                                        <SelectPicker
                                            className={`select pageLength`}
                                            menuClassName={`selectMenu`}
                                            data={[
                                                { label: "20 ITEMS", value: 20 },
                                                { label: "50 ITEMS", value: 50 },
                                                { label: "100 ITEMS", value: 100 },
                                                { label: "200 ITEMS", value: 200 }
                                            ]}
                                            searchable={false}
                                            cleanable={false}
                                            value={pagination.size}
                                            onChange={(value: number | null) =>
                                                updatePagination({
                                                    pages:
                                                        value !== null
                                                            ? Math.ceil(pagination.total / value)
                                                            : 1,
                                                    current: 1,
                                                    size: value
                                                })
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {table.schema && (
                        <Table
                            className="tableContainer"
                            data={table.data}
                            height={"height" in props ? props.height : 500}
                            headerHeight={50}
                            rowHeight={"rowHeight" in props ? props.rowHeight : 60}
                            hover={false}
                            sortColumn={sorting.column}
                            sortType={sorting.type}
                            onSortColumn={(sortColumn, sortType) =>
                                setSorting({
                                    column: sortColumn,
                                    type: sortType
                                })
                            }
                            affixHorizontalScrollbar
                        >
                            {table.schema.map((field, index) => (
                                <Column
                                    verticalAlign={`middle`}
                                    align={`left`}
                                    width={field.width}
                                    resizable
                                    sortable={props.sortable}
                                >
                                    <HeaderCell verticalAlign={`top`}>{field.name}</HeaderCell>
                                    <Cell dataKey={field.key} />
                                </Column>
                            ))}

                            {typeof table.data !== "undefined" &&
                                table.data.length > 0 &&
                                (props.viewable ||
                                    props.insertable ||
                                    props.editable ||
                                    props.removable) && (
                                    <Column
                                        verticalAlign={`middle`}
                                        align={`center`}
                                        width={actionButtonWidth()}
                                        fixed={isMobile ? false : "right"}
                                    >
                                        <HeaderCell verticalAlign={`top`}>Action</HeaderCell>
                                        <Cell>
                                            {(rowData, rowIndex) => (
                                                <>
                                                    {props.viewable && (
                                                        <button
                                                            type="button"
                                                            className="button btn btn-outline-primary mr-3"
                                                            onClick={() => {
                                                                if (
                                                                    typeof props.onViewButtonClick ===
                                                                    "function"
                                                                )
                                                                    props.onViewButtonClick(
                                                                        rowData
                                                                    );
                                                            }}
                                                        >
                                                            VIEW
                                                        </button>
                                                    )}

                                                    {props.insertable && (
                                                        <button
                                                            type="button"
                                                            className={`button btn btn-outline-success mr-3`}
                                                            onClick={() => {
                                                                if (
                                                                    typeof props.onInsertButtonClick ===
                                                                    "function"
                                                                )
                                                                    props.onInsertButtonClick(
                                                                        rowData
                                                                    );
                                                            }}
                                                        >
                                                            ADD
                                                        </button>
                                                    )}

                                                    {props.editable && (
                                                        <button
                                                            type="button"
                                                            className="button btn btn-outline-warning mr-3"
                                                            disabled={rowData.permitID}
                                                            onClick={() => {
                                                                if (
                                                                    typeof props.onEditButtonClick ===
                                                                    "function"
                                                                )
                                                                    props.onEditButtonClick(
                                                                        rowData
                                                                    );
                                                            }}
                                                        >
                                                            EDIT
                                                        </button>
                                                    )}

                                                    {props.removable && (
                                                        <button
                                                            type="button"
                                                            className="button btn btn-outline-danger mr-3"
                                                            disabled={rowData.permitID}
                                                            onClick={() => {
                                                                if (
                                                                    typeof props.onRemoveButtonClick ===
                                                                    "function"
                                                                )
                                                                    props.onRemoveButtonClick(
                                                                        rowData
                                                                    );
                                                            }}
                                                        >
                                                            REMOVE
                                                        </button>
                                                    )}

                                                    {typeof props.machine !== "undefined" && (
                                                        <button
                                                            type="button"
                                                            className="button btn mr-3 bg-transparent"
                                                        >
                                                            <div>Is Operator</div>
                                                            <div>
                                                                <Toggle
                                                                    checked={
                                                                        rowData.zoneName in
                                                                            props.machine
                                                                            ? props.machine[
                                                                            rowData.zoneName
                                                                            ]
                                                                            : false
                                                                    }
                                                                    onChange={(checked) => {
                                                                        if (
                                                                            typeof props.toggleMachine ===
                                                                            "function"
                                                                        )
                                                                            props.toggleMachine(
                                                                                rowData,
                                                                                checked
                                                                            );
                                                                    }}
                                                                />
                                                            </div>
                                                        </button>
                                                    )}

                                                    {typeof props.controlZone !== "undefined" && (
                                                        <button
                                                            type="button"
                                                            className="button btn mr-3 bg-transparent"
                                                        >
                                                            <div>Is Control Zone</div>
                                                            <div>
                                                                <Toggle
                                                                    checked={
                                                                        rowData.zoneName in
                                                                            props.controlZone
                                                                            ? props.controlZone[
                                                                            rowData.zoneName
                                                                            ]
                                                                            : true
                                                                    }
                                                                    onChange={(checked) => {
                                                                        if (
                                                                            typeof props.toggleControlZone ===
                                                                            "function"
                                                                        )
                                                                            props.toggleControlZone(
                                                                                rowData,
                                                                                checked
                                                                            );
                                                                    }}
                                                                />
                                                            </div>
                                                        </button>
                                                    )}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                )}
                        </Table>
                    )}

                    {pagination.pages > 0 && props.pagination && (
                        <div className="d-xl-flex d-block justify-content-between align-items-center">
                            <div className="fonts font-16">
                                Total{" "}
                                <span className="fonts weight-700 mx-2">{pagination.total}</span>{" "}
                                Records
                            </div>
                            <div className="my-5">
                                <Pagination
                                    prev
                                    next
                                    ellipsis
                                    boundaryLinks
                                    size="lg"
                                    total={pagination.pages}
                                    pages={pagination.pages}
                                    activePage={pagination.current}
                                    maxButtons={5}
                                    onSelect={(key: any) =>
                                        setPagination({ ...pagination, current: key })
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Loading componentLoader={true} loading={!props.fetched} className="table-loader" />
        </>
    );
};

export default DataTable;
