import React, {useCallback, useContext, useState} from 'react';
import {useParams} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";

import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";

import Modal from '../components/Modal';
import DataTable from "../components/DataTable";
import API from "../helpers/API";
import EditSensorConfigForm from '../components/SensorConfig/Form';

const SensorConfigPage = (props: any) => {
  const {alert, setAlert} = useContext(ComponentContext);
  const {main, resetToken} = useContext(MainContext);
  const {sensorId} = useParams();
  const [table, setTable] = useState({
    fetched: false
  })

  const [modal, setModal] = useState({
    show: false,
    close: false,
    loading: false,
    title: 'Edit sensor config form',
    data: {}
  });

  const [selectedSensor, setSelectedSensor] = useState({});

  const form = useFormik({
    initialValues: selectedSensor,
    validationSchema: Yup.object({
      lowerValie: Yup.number(),
    }),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values: any) => {
        values.withinRange = typeof values.withinRange === 'boolean' 
        ? values.withinRange
        : (values.withinRange === 'Yes'
            ? true : false);
        values.upperValue = parseFloat(values.upperValue) ?? null;
        values.lowerValue = parseFloat(values.lowerValue) ?? null;
        
        updateSensorConfig(values);
    },
  })

  const closeModal = useCallback(() => {
    form.resetForm();
    setModal({...modal, show: false})
  }, [modal]);

  const updateSensorConfig = useCallback((values: any) => {
    setModal({...modal, loading: true})

    let formData = JSON.parse(JSON.stringify(values));

    API.call({
      url: `${main.apiUrl}/sensorConfig`,
      options: {
        method: 'put',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
      },
      successCallback: (res) => {
        setAlert({
          ...alert,
          show: true,
          type: 'success',
          message: res.message.content
        })

        setModal({...modal, loading: false, close: true})

        setTable({...table, fetched: false})
      },
      errorCallback: (error) => {
        setAlert({
          ...alert,
          show: true,
          type: error.message.type,
          message: error.message.content
        })

        setModal({...modal, loading: false})
      },
      resetToken: resetToken
    });
  }, [alert, main, modal, table, resetToken, setAlert]);

  return (
    <>
      <section className={`content ${props.sidebar}`}>
        <header className="content__title">
          <h1 className="mr-auto">Sensor config<small>List of sensor configs</small></h1>
        </header>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <DataTable
                      fetched={table.fetched}
                      dataSource={`REMOTE`}
                      pagination={false}
                      sortable={true}
                      searchable={false}
                      viewable={false}
                      insertable={false}
                      editable={true}
                      removable={false}
                      dataUrl={`${main.apiUrl}/sensorConfig/list?sensorID=${sensorId}`}
                      onDataFetch={() => {
                      }}
                      onFetched={() => {
                        setTable({...table, fetched: true})
                      }}
                      onEditButtonClick={(rowData) => {
                        setSelectedSensor(rowData);
                        setModal({...modal, title: 'Edit sensor config form', show: true, close: false, data: rowData})
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={modal.show}
        close={modal.close}
        loading={modal.loading}
        title={modal.title}
        scrollable={true}
        size={"lg"}
        closeButton={true}
        confirmButtons={false}
        closeAction={closeModal}
        centered={true}
      >
        {(modal.show) &&
          <>
            <EditSensorConfigForm form={form} data={modal.data}/>
          </>
        }
      </Modal>
    </>
  );
}

export default SensorConfigPage;