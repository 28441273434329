import React, {useCallback, useContext, useState} from 'react';
import {useParams} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";

import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";

import Modal from '../components/Modal';
import DataTable from "../components/DataTable";
import GasSensorForm from "../components/GasSensor/Form";

import {GasSensorFormFields} from "../types/GasSensorForm";
import API from "../helpers/API";
import is from '../assets/lib/material/resources/vendors/fullcalendar/core/locales/is';

const defaultFormValues: GasSensorFormFields = {
  id: '',
  projectID: '',
  zoneID: '',
  isBillboard: false,
  deviceID: '',
  deviceName: '',
}

const GasSensorPage = (props: any) => {
  const {alert, setAlert} = useContext(ComponentContext);
  const {main, resetToken} = useContext(MainContext);
  const {id} = useParams();
  const [table, setTable] = useState({
    fetched: false
  })

  const [modal, setModal] = useState({
    show: false,
    close: false,
    loading: false,
    title: 'Add Gas Sensor Form',
    data: {}
  });

  const [removeModal, setRemoveModal] = useState({
    show: false,
    close: false,
    loading: true,
    data: {id: ''}
  });

  const navigate = useNavigate();

  const form = useFormik({
    initialValues: defaultFormValues,
    validationSchema: Yup.object({
      zoneID: Yup.string().required("Please select Zone"),
      deviceID: Yup.string().required("Please input Device ID"),
      isBillboard: Yup.boolean().required("Please select Billboard Status"),
      deviceName: Yup.string(),
    }),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      if (values.id === '') {
        createGasSensor(values);
      }
    },
  })

  const closeModal = useCallback(() => {
    form.resetForm();
    setModal({...modal, show: false})
  }, [modal]);

  const createGasSensor = useCallback((values: any) => {
    setModal({...modal, loading: true})

    let formData = JSON.parse(JSON.stringify(values));

    formData.projectID = id;

    API.call({
      url: `${main.apiUrl}/gas-sensors`,
      options: {
        method: 'post',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
      },
      successCallback: (res) => {
        setAlert({
          ...alert,
          show: true,
          type: 'success',
          message: res.message.content
        })

        setModal({...modal, loading: false, close: true})

        setTable({...table, fetched: false})
      },
      errorCallback: (error) => {
        setAlert({
          ...alert,
          show: true,
          type: error.message.type,
          message: error.message.content
        })

        setModal({...modal, loading: false})
      },
      resetToken: resetToken
    });
  }, [modal, table]);

  const removeGasSensor = (id: string) => {
    API.call({
      url: `${main.apiUrl}/gas-sensors`,
      options: {
        method: 'delete',
        credentials: 'include',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({id: id})
      },
      successCallback: (res) => {
        setAlert({
          ...alert,
          show: true,
          type: res.message.type,
          message: res.message.content
        })

        setTable({...table, fetched: false})
      },
      errorCallback: (error) => {
        setAlert({
          ...alert,
          show: true,
          type: error.message.type,
          message: error.message.content
        })
      },
      resetToken: resetToken
    });
  }

  return (
    <>
      <section className={`content ${props.sidebar}`}>
        <header className="content__title">
          <h1 className="mr-auto">Gas Sensors<small>List of gas sensors in different zones</small></h1>
          <button className="btn button btn-theme" onClick={e => {
            setModal({...modal, title: 'Add Gas Sensor', show: true, close: false})
          }}>
            Add Gas Sensor
          </button>
        </header>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <DataTable
                      fetched={table.fetched}
                      dataSource={`REMOTE`}
                      pagination={true}
                      sortable={true}
                      searchable={true}
                      viewable={false}
                      insertable={false}
                      editable={true}
                      removable={true}
                      dataUrl={`${main.apiUrl}/gas-sensors?projectID=${id}`}
                      onDataFetch={() => {
                      }}
                      onFetched={() => {
                        setTable({...table, fetched: true})
                      }}
                      onRemoveButtonClick={(rowData) => {
                        setRemoveModal({...removeModal, show: true, data: {id: rowData.id}})
                      }}
                      onEditButtonClick={(rowData) => {
                        navigate(`/${id}/${rowData.id}/gas/sensorConfig`)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={modal.show}
        close={modal.close}
        loading={modal.loading}
        title={modal.title}
        scrollable={true}
        size={"lg"}
        closeButton={true}
        confirmButtons={false}
        closeAction={closeModal}
        centered={true}
      >
        {(modal.show) &&
          <>
            <GasSensorForm projectID={id} form={form}/>
          </>
        }
      </Modal>

      <Modal
        show={removeModal.show}
        close={removeModal.close}
        title={'Confirm Remove Gas Sensor'}
        scrollable={true}
        size={"md"}
        centered={false}
        confirmButtons={true}
        buttonName={'Confirm Remove'}
        buttonClass={'btn-danger'}
        closeAction={() => {
          setRemoveModal({...removeModal, show: false})
        }}
        confirmedAction={() => {
          removeGasSensor(removeModal.data.id);
        }}
      >
        Confirm Remove Gas Sensor?<br/>THIS CANNOT BE UNDONE!!!
      </Modal>
    </>
  );
}

export default GasSensorPage;