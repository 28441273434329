import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PageHeader from "../components/PageHeader";
import { MainContext } from "../contexts/MainContext";
import { useParams } from "react-router-dom";
import Modal from "../components/Modal";
import SimpleTable, { SimpleTableData } from "../components/SimpleDataTable/SimpleDataTable";
import API from "../helpers/API";
import { ComponentContext } from "../contexts/ComponentContext";
import { ProjectConfigRes, ProjectConfigResData } from "../models/apiRes/ProjectConfigModel";
import Toggle from "rsuite/Toggle";
import ProjectConfigEditPanel, {
    ProjectConfigFormObject
} from "../components/ProjectConfigEditPanel";
import SimpleDataActionBtns from "../components/SimpleDataTable/SimpleDataActionBtns";
import { has } from "lodash";

const SIDEBAR_CLASSNAME = "with-sidebar";

interface ProjectConfigTableData {
    id: string;
    item: string;
    value: string;
}

const NUMBER_TYPE_FIELDS = ["lat", "long", "zoomLvl"];

const PROJECT_CONFIG_TABLE_SCHEMA: Array<SimpleTableData> = [
    {
        key: "item",
        headerLabel: "Item",
        width: 260
    },
    {
        key: "value",
        headerLabel: "Value",
        customColumn: true
    },
    {
        key: "action",
        headerLabel: "Action",
        customColumn: true,
        width: 120
    }
];
interface ProjectConfigPageProps {
    sidebar: boolean;
}

const ProjectConfigPage = ({ sidebar }: ProjectConfigPageProps) => {
    const { alert, setAlert } = useContext(ComponentContext);
    const { main, resetToken } = useContext(MainContext);
    const { id } = useParams();

    const [resData, setResData] = useState<ProjectConfigResData>();
    const [modalLoading, setModalLoading] = useState(false);
    const [selectedEditField, setSelectedEditField] = useState("");

    const errorCallbackHandle = useCallback(
        (error: any) => {
            setModalLoading(false);
            setModalLoading(false);
            setAlert({
                ...alert,
                type: error.message.type,
                message: error.message.content
            });
        },
        [alert, setAlert]
    );

    const fetchData = useCallback(async () => {
        API.call({
            url: `${main.apiUrl}/projects/config?pid=${id}`,
            options: {
                method: "get",
                credentials: "include",
                headers: { "Content-Type": "application/json" }
            },
            successCallback: (res: ProjectConfigRes) => {
                setResData(res.data);
            },
            errorCallback: errorCallbackHandle,
            resetToken: resetToken
        });
    }, [errorCallbackHandle, id, main.apiUrl, resetToken]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const tableData: Array<ProjectConfigTableData> = useMemo(() => {
        if (!resData) return [];

        const { lat, long, isGIS, zoomLvl, hasBillboard } = resData;
        return [
            {
                id: "lat",
                item: "Latitude",
                value: lat?.toString() ?? 0
            },
            {
                id: "long",
                item: "Longitude",
                value: long?.toString() ?? 0
            },
            {
                id: "isGIS",
                item: "GIS Map",
                value: isGIS.toString()
            },
            {
                id: "hasBillboard",
                item: "Billboard",
                value: hasBillboard.toString()
            },
            {
                id: "zoomLvl",
                item: "Zoom Level",
                value: zoomLvl?.toString() ?? 1
            }
        ];
    }, [resData]);

    const findLabelByFieldId = (fieldId: string) => {
        const found = tableData.find((rowData) => rowData.id === fieldId);
        return found?.item ?? "";
    };

    const findDefaultValueByFieldId = (fieldId: string): string | boolean => {
        if (!resData) return "";

        return (resData as any)[fieldId];
    };

    const onSubmitEditedField = useCallback(
        async (fieldName: string, value: string | boolean) => {
            if (!resData) return;

            setModalLoading(true);
            const { isGIS, zoomLvl, lat, long, _id , hasBillboard } = resData;
            const convertedValue = NUMBER_TYPE_FIELDS.includes(fieldName)
                ? parseFloat(value.toString())
                : value;

            const sentBody = {
                ...{
                    id: _id,
                    isGIS,
                    zoomLvl,
                    lat,
                    hasBillboard,
                    long
                },
                [fieldName]: convertedValue
            };

            API.call({
                url: `${main.apiUrl}/projects/config`,
                options: {
                    method: "put",
                    credentials: "include",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(sentBody)
                },
                successCallback: (res: ProjectConfigRes) => {
                    setModalLoading(false);
                    setSelectedEditField("");
                    setResData(res.data);
                },
                errorCallback: errorCallbackHandle,
                resetToken: resetToken
            });
        },
        [errorCallbackHandle, main.apiUrl, resData, resetToken]
    );

    const customEditPanelValidation = (
        values: ProjectConfigFormObject,
        errors: Record<string, string>
    ) => {
        if (
            selectedEditField === "zoomLvl" &&
            (parseInt(values.configInput) < 1 || parseInt(values.configInput) > 24)
        ) {
            errors.configInput = "Out of range 1-24";
        }
        return errors;
    };

    const customCellRenderer = (schemaKey: string, rowData: ProjectConfigTableData) => {
        if (schemaKey === "action") {
            return <SimpleDataActionBtns onClickEdit={() => setSelectedEditField(rowData.id)} />;
        }

        if (schemaKey === "value") {
            if (rowData.item === "GIS Map" || rowData.item === "Billboard")
                return (
                    <Toggle
                        className="project-config-toggle"
                        checked={rowData.value === "true" ? true : false}
                    />
                );
            
            return <>{rowData.value}</>;
        }

        return <></>;
    };

    return (
        <>
            <section className={`content ${sidebar && SIDEBAR_CLASSNAME}`}>
                <PageHeader title="Project Config" description="List of project config" />

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <SimpleTable
                                            schema={PROJECT_CONFIG_TABLE_SCHEMA}
                                            data={tableData}
                                            customCellRenderer={customCellRenderer}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                show={Boolean(selectedEditField)}
                close={!selectedEditField}
                loading={modalLoading}
                title={"Project Config"}
                scrollable={false}
                size={"lg"}
                closeButton={true}
                confirmButtons={false}
                closeAction={() => setSelectedEditField("")}
                centered={true}
            >
                <ProjectConfigEditPanel
                    label={findLabelByFieldId(selectedEditField)}
                    defaultValue={findDefaultValueByFieldId(selectedEditField)}
                    onSubmitCallback={(value: string | boolean) =>
                        onSubmitEditedField(selectedEditField, value)
                    }
                    customValidation={customEditPanelValidation}
                />
            </Modal>
        </>
    );
};

export default ProjectConfigPage;
