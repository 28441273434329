import { useParams } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import MenuItem from "../components/MenuItem";


const SensorConfigLayout = (props: any) =>
{
    const { zoneId, sensorType = '' } = useParams();

    const getSensorPathByType = (type: string) => {
        switch (type) {
            case 'env':
                return 'env-sensors';
            case 'gas':
                return 'gas-sensors';
            default:
                return '';
        }
    
    }

	return <>
		<Header sidebar={props.sidebar}/>

		<div className="main">
			{(props.sidebar) &&
			<>
				<Sidebar>
                    <li>
                        <MenuItem
                            activeColor={"white"}
                            icon={"arrow-left"}
                            label={"Back To Sensor Page"}
                            location={`/${zoneId}/${getSensorPathByType(sensorType)}`}
                            end={true}
                        />
                    </li>
					<li>
						<MenuItem activeColor={"blue"} icon={"grid"} label={"Sensor Config"} end={true}/>
					</li>
				</Sidebar>
			</>
			}
			{props.component}
		</div>
		<footer className="footer" style={{padding:'2rem 1rem'}}>
			Admin Panel SIT v2.0.0
		</footer>
	</>
}

export default SensorConfigLayout;