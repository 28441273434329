import { useState } from "react";
import { Row, Col, Grid } from "rsuite";

type EditSensorConfigFormProps = {
  form: any,
  data: any
}

const EditSensorConfigForm = ({form, data}: EditSensorConfigFormProps) => {
  
  const [isWithinRange, setIsWithinRange] = useState(typeof data?.withinRange === 'boolean' ? data?.withinRange : data?.withinRange === 'Yes');

  const toggleWithinRange = () => {
    setIsWithinRange(!isWithinRange);
    form.values.withinRange = !isWithinRange;
  }

  return <>
    <form onSubmit={form.handleSubmit}>
        <Grid fluid>
            <Row className="row py-4">
                <Col sm={8}>
                    <div>Device ID</div>
                    <input
                        id="deviceID"
                        name="deviceID"
                        type="text"
                        className="form-control"
                        value={data?.deviceID}
                        autoComplete="off"
                        disabled={true}/>
                </Col>
                <Col sm={8}>
                    <div>Reading Type</div>
                    <input
                        id="readingType"
                        name="readingType"
                        type="text"
                        className="form-control"
                        value={data?.readingType}
                        autoComplete="off"
                        disabled={true}/>
                </Col>
                <Col sm={8}>
                    <div>Alert Level</div>
                    <input
                        id="alertLevel"
                        name="alertLevel"
                        type="text"
                        className="form-control"
                        value={data?.alertLevel}
                        autoComplete="off"
                        disabled={true}/>
                </Col>
            </Row>
            <Row className="row py-4">
                <span style={{color: "yellow"}}>*The alert will not be triggered if both lower and upper values are empty</span>
                <Col sm={12}>
                    <div>
                        Lower Value <br />
                        *Leave it empty if you don't want to set the lower value
                    </div>
                    <input
                        id="lowerValue"
                        name="lowerValue"
                        type="number"
                        className="form-control"
                        onChange={form.handleChange}
                        value={form.values.lowerValue}
                        autoComplete="off"/>
                </Col>
                <Col sm={12}>
                    <div>
                        Upper Value <br />
                        *Leave it empty if you don't want to set the upper value
                    </div>
                    <input
                        id="upperValue"
                        name="upperValue"
                        type="number"
                        className="form-control"
                        onChange={form.handleChange}
                        value={form.values.upperValue}
                        autoComplete="off"/>
                </Col>
            </Row>
            <Row className="row py-4">
                <Col sm={24}>
                    <div>
                        With in the range <br />
                        *Checked if you want to trigger the alert when the value is within the range, otherwise it will trigger when the value is out of the range
                    </div>
                    <input
                        id="withinRange"
                        name="withinRange"
                        type="checkbox"
                        onChange={toggleWithinRange}
                        checked={isWithinRange}
                        autoComplete="off"/>
                </Col>
            </Row>
        </Grid>
      <div className="text-right">
        <button type="submit" className="btn button btn-theme">Submit</button>
      </div>
    </form>
  </>
}

export default EditSensorConfigForm;